<template>
    <button class="exerciseCard__actionBtn"
            :disabled="disabled"
            @click.prevent="$emit('click')">
        <svg class="h-3/5 w-3/5 fill-current" viewBox="0 0 20 20">
            <path fill-rule="evenodd"
                  d="M4 2a1 1 0 011 1v2.101a7.002 7.002 0 0111.601 2.566 1 1 0 11-1.885.666A5.002 5.002 0 005.999 7H9a1 1 0 010 2H4a1 1 0 01-1-1V3a1 1 0 011-1zm.008 9.057a1 1 0 011.276.61A5.002 5.002 0 0014.001 13H11a1 1 0 110-2h5a1 1 0 011 1v5a1 1 0 11-2 0v-2.101a7.002 7.002 0 01-11.601-2.566 1 1 0 01.61-1.276z"
                  clip-rule="evenodd"/>
        </svg>
    </button>
</template>

<script>
    export default {
        name : 'ResetBtn',
        props: {
            disabled: {
                type   : Boolean,
                default: false,
            },
        },
    };
</script>
