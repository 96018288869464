<template>
    <div class="exercise max-w-sm mx-auto">
        <p class="exercise__title" v-text="instructionString"/>
        <div class="mx-auto flex flex-wrap justify-center max-w-xs">
            <card
                v-for="(card, index) in cards"
                :key="index"
                class="m-1"
                :class="multipleChoiceClassName"
                :image-src="card.hasImage() ? card.getImageSrc() : null"
                :show-image="showImages"
                :active="getCardActiveStatus(card)"
                :is-clickable="!markRequested"
                @click="handleClick(card.getLocalText())">
                <span v-text="card.getLocalText()"/>
            </card>
        </div>
    </div>
</template>

<script>
    import {mapGetters} from 'vuex';
    import cloneDeep from 'lodash.clonedeep';
    import Exercise from '@/entities/Exercise';
    import Card from '@/components/partials/Card';
    import {shuffle} from '@/helpers/ArrayHelpers';

    export default {
        name      : 'MultipleChoiceExercise',
        data() {
            return {
                selected: null,
            };
        },
        props     : {
            showImages: {
                required: false,
                type    : Boolean,
                default : true,
            },
            exercise  : {
                required : true,
                validator: ex => ex instanceof Exercise,
            },
        },
        methods   : {
            /**
             * Handle selecting a given card.
             *
             * @returns {void}
             */
            handleClick(selected) {
                if (this.markRequested === false) {
                    this.selected = selected;
                }
            },

            /**
             * Determine whether the given card should show the correct validation.
             *
             * @param {*} item
             *
             * @returns {Boolean}
             */
            shouldHighlightCorrect(item) {
                return true === this.markRequested
                    && item.getLocalText() === this.correctEntity.getLocalText()
                    && item.getLocalText() === this.selected;
            },

            /**
             * Determine whether the given card should show the incorrect validation.
             *
             * @param {*} item
             *
             * @returns {Boolean}
             */
            shouldHighlightIncorrect(item) {
                return true === this.markRequested
                    && item.getLocalText() !== this.correctEntity.getLocalText()
                    && item.getLocalText() === this.selected;
            },

            /**
             * Get the card active status.
             *
             * @param card
             *
             * @return {Boolean|null}
             */
            getCardActiveStatus(card) {
                if (this.selected !== null) {
                    return this.selected === card.getLocalText();
                } else {
                    return null;
                }
            },
        },
        computed  : {
            ...mapGetters({
                markRequested: 'Exercise/markRequested',
                interfaceIso : 'Account/getInterfaceIso',
            }),

            instructionString() {
                let iso  = this.interfaceIso;
                let word = this.correctEntity.getTranslationFor(iso);
                let selectInsruction = this.$t('EXERCISES.MULTIPLE_CHOICE_SELECT_TITLE', {x: word});

                return selectInsruction;
            },

            correctEntity() {
                return this.exercise.getSingleEntity();
            },

            incorrectEntities() {
                return this.exercise.getIncorrectEntities();
            },

            cards() {
                let cards = cloneDeep(this.incorrectEntities);
                cards.push(cloneDeep(this.correctEntity));

                return shuffle(cards);
            },

            isCorrect() {
                return this.selected !== null
                    && this.selected === this.correctEntity.getLocalText();
            },

            canProceed() {
                return this.selected !== null;
            },

            multipleChoiceClassName() {
                return true === this.showImages
                    ? 'exerciseCard--multipleChoiceWithImages'
                    : 'exerciseCard--multipleChoiceWithoutImages';
            },
        },
        watch     : {
            isCorrect : {
                immediate: true,
                handler  : function (value) {
                    this.$store.commit('Exercise/setIsCorrect', value);
                },
            },
            canProceed: {
                immediate: true,
                handler  : function (value) {
                    this.$store.commit('Exercise/setCanProceed', value);
                },
            },
        },
        components: {
            Card,
        },
    };
</script>
