<template>
    <div class="fixed bottom-0 left-0 w-full">
        <div class="p-2 max-w-xs mx-auto text-center">
            <div v-if="exerciseHasMarked">
                <p v-if="exerciseIsCorrect"
                   class="unitControls__markText unitControls__markText--correct">
                    {{ $t('EXERCISES.THATS_RIGHT') }}
                </p>
                <p v-if="!exerciseIsCorrect"
                   class="unitControls__markText unitControls__markText--incorrect">
                    {{ $t('EXERCISES.AFRAID_NOT') }}
                </p>
            </div>
            <div class="mt-2 mb-1">
                <mark-controls v-if="shouldShowMarkControls"/>
                <proceed-controls v-else/>
            </div>
        </div>
    </div>
</template>

<script>
    import {mapGetters} from 'vuex';
    import MarkControls from '@/components/partials/Exercise/controls/MarkControls';
    import ProceedControls from '@/components/partials/Exercise/controls/ProceedControls';
    import {
        UNIT_TYPE_LISTEN_TYPE,
        UNIT_TYPE_MULTIPLE,
        UNIT_TYPE_MULTIPLE_NO_IMAGES,
        UNIT_TYPE_PHRASE,
        UNIT_TYPE_FILL_IN_THE_GAPS,
    } from '@/constants/Unit';

    export default {
        name      : 'Controls',
        computed  : {
            ...mapGetters({
                exerciseHasMarked    : 'Exercise/markRequested',
                exerciseType         : 'Exercise/getType',
                exerciseIsCorrect    : 'Exercise/isCorrect',
                exerciseAllowsProceed: 'Exercise/canProceed',
            }),

            successBtnText() {
                switch (this.exerciseType) {
                    case UNIT_TYPE_PHRASE:
                        return this.$t('CORE.CONTINUE');
                    default:
                        return this.$t('CORE.CHECK');
                }
            },

            failBtnText() {
                switch (this.exerciseType) {
                    // case UNIT_TYPE_PHRASE:
                    //     return 'Continue'
                    default:
                        return this.$t('CORE.SKIP');
                }
            },

            canShowSucceed() {
                return [
                    UNIT_TYPE_PHRASE,
                    UNIT_TYPE_MULTIPLE,
                    UNIT_TYPE_FILL_IN_THE_GAPS,
                ].includes(this.exerciseType);
            },

            canProceedWithSucceed() {
                switch (this.exerciseType) {
                    case UNIT_TYPE_PHRASE:
                        return true;
                    case UNIT_TYPE_MULTIPLE:
                        return this.exerciseAllowsProceed;
                    default:
                        return false;
                }
            },

            canShowFail() {
                switch (this.exerciseType) {
                    case UNIT_TYPE_PHRASE:
                        return false;
                    case UNIT_TYPE_MULTIPLE:
                        return true;
                    default:
                        return true;
                }
            },

            canProceedWithFail() {
                switch (this.exerciseType) {
                    case UNIT_TYPE_PHRASE:
                        return false;
                    case UNIT_TYPE_MULTIPLE:
                        return true;
                    default:
                        return true;
                }
            },

            shouldShowMarkControls() {
                const exercisesToShowControls = [
                    UNIT_TYPE_MULTIPLE,
                    UNIT_TYPE_MULTIPLE_NO_IMAGES,
                    UNIT_TYPE_LISTEN_TYPE,
                    UNIT_TYPE_FILL_IN_THE_GAPS,
                ];

                return this.exerciseHasMarked === false
                    && exercisesToShowControls.includes(this.exerciseType);
            },
        },
        components: {
            MarkControls,
            ProceedControls,
        },
    };
</script>
