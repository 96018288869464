<template>
    <div class="unitPage" :class="additionalClasses">
        <div class="mx-auto container">
            <loading-capsule :loaded="unitHasFullyLoaded" spinner-margin-top="40vh">
                <div v-if="unitHasFullyLoaded">
                    <div class="unitContainer">
                        <progress-navigation
                            :progress="progress"
                            @exit="exitUnit"/>
                        <div class="exercise exercise__container">
                            <exercise :key="exercise.id" v-if="exercise"/>
                        </div>
                    </div>
                    <controls :key="exercise && exercise.id"/>
                </div>
            </loading-capsule>
        </div>
    </div>
</template>

<script>
    import {mapGetters} from 'vuex';
    import VuexUtils from '@/mixins/VuexUtils';
    import {TYPE_UNIT} from '@/constants/Progress';
    import ExitButton from '@/components/ExitButton';
    import ProgressBar from '@/components/ProgressBar';
    import Controls from '@/components/partials/Exercise/Controls';
    import Exercise from '@/components/partials/Exercise/Exercise';
    import LoadingCapsule from '@/components/structure/LoadingCapsule';
    import ProgressNavigation from '@/components/partials/Unit/ProgressNavigation';

    export default {
        name      : 'Unit',
        mixins    : [VuexUtils],
        mounted() {
            if (null === this.unitUuid) {
                return this.$router.push({name: 'app.dashboard'});
            }
        },
        methods   : {
            /**
             * Handle closing the unit early.
             *
             * @returns {void}
             */
            exitUnit() {
                this.$store.commit('Unit/resetState');
                this.$store.commit('Exercise/resetState');
                this.$router.push({name: 'app.dashboard'});
            },

            /**
             * Finalise the progress events and push the entire batch to the API for processing
             *
             * @return {void}
             */
            handlePushingProgressToApi() {
                // Add unit to progress
                if (this.unit) {
                    const payload = {
                        type  : TYPE_UNIT,
                        id    : this.unit.id,
                        passed: this.hasUserPassedUnit,
                        score : this.unitScore,
                    };

                    this.$store.dispatch('ProgressDispatcher/addProgressRecord', payload);
                }

                // Push progress
                this.$store.dispatch('ProgressDispatcher/sendToApi')
                    .then(this.resetUserProgressStores)
                    .then(() => {
                        this.hasUserPassedUnit
                            ? this.$router.push({name: 'app.learn.unit.success'})
                            : this.$router.push({name: 'app.learn.unit.fail'});
                    });
            },
        },
        computed  : {
            ...mapGetters({
                unit             : 'Unit/getUnitData',
                unitScore        : 'Unit/getScore',
                unitUuid         : 'Unit/getActiveUnitUuid',
                hasUserPassedUnit: 'Unit/hasUserPassed',
                exercise         : 'Unit/getCurrentExercise',
                progress         : 'Unit/getCurrentUnitProgressPercentage',
                hasFinished      : 'Unit/unitHasFinished',
                exerciseHasMarked: 'Exercise/markRequested',
                exerciseType     : 'Exercise/getType',
                exerciseIsCorrect: 'Exercise/isCorrect',
            }),

            additionalClasses() {
                let classes = this.exerciseType;

                if (this.exerciseHasMarked) {
                    classes += this.exerciseIsCorrect
                        ? ` unitPage--correct`
                        : ` unitPage--incorrect`;
                }

                return classes;
            },

            unitHasFullyLoaded() {
                return null !== this.unit;
            },
        },
        watch     : {
            hasFinished(value) {
                if (value === true) {
                    this.handlePushingProgressToApi();
                }
            },
            unit: {
                immediate: true,
                handler  : function (value) {
                    if (null !== value) {
                        this.$store.dispatch('Unit/startUnit');
                    }
                },
            },
        },
        components: {
            ProgressBar,
            ExitButton,
            Exercise,
            Controls,
            ProgressNavigation,
            LoadingCapsule,
        },
    };
</script>
