<template>
    <div>
        <!--Check button-->
        <btn
            class="mx-auto button__btn--primary button__btn--lockedWidth"
            :disabled="!exerciseAllowsProceed"
            @click="handleSucceedClick"
            v-text="$t('CORE.CHECK')"/>
    </div>
</template>

<script>
    import {mapGetters} from 'vuex';
    import Btn from '@/components/Btn';
    import {AUDIO_BASE_64_CORRECT, AUDIO_BASE_64_INCORRECT} from '@/constants/Audio';

    export default {
        name      : 'MarkControls',
        data      : () => {
            return {
                mp3s: {
                    correct  : AUDIO_BASE_64_CORRECT,
                    incorrect: AUDIO_BASE_64_INCORRECT,
                },
            };
        },
        methods   : {
            /**
             * Handle the check click event.
             *
             * @returns {void}
             */
            handleSucceedClick() {
                if (this.exerciseAllowsProceed) {
                    this.$store.commit('Exercise/setMarkRequested', true);
                    this.playMarkAudio();
                }
            },

            /**
             * Play the mark music based on whether the user passed or not.
             *
             * @return {void}
             */
            playMarkAudio() {
                switch (this.exerciseIsCorrect) {
                    case true:
                        (new Audio(this.mp3s.correct)).play();
                        break;
                    case false:
                        (new Audio(this.mp3s.incorrect)).play();
                        break;
                }
            },
        },
        computed  : {
            ...mapGetters({
                exerciseType         : 'Exercise/getType',
                exerciseIsCorrect    : 'Exercise/isCorrect',
                exerciseAllowsProceed: 'Exercise/canProceed',
            }),
        },
        components: {
            Btn,
        },
    };
</script>
