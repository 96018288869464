<template>
    <div class="exercise max-w-sm mx-auto">
        <p class="exercise__title" v-text="$t('EXERCISES.FILL_IN_THE_BLANKS_TITLE')"/>

        <!--Phrase preview-->
        <div class="mt-6">
            <card
                    class="exerciseCard--fillInTheGaps mx-auto"
                    :audio-src="audioSrc">
                <template v-slot:actions>
                    <div class="flex justify-center">
                        <audio-preview-btn
                                class="ml-1"
                                :src="audioSrc"/>
                        <reset-btn class="mx-1"
                                   :disabled="exerciseHasMarked"
                                   @click="resetExercise"/>
                    </div>
                </template>
                <p class="exerciseCard__sentence">
                    <span v-for="item in phraseArray">
                        <span-entity-input-pill
                                v-if="item.type === 'PHRASE_ENTITY'"
                                :is-correct="item.isCorrect"
                                :show-correct-validation="shouldHighlightCorrect(item)"
                                :show-incorrect-validation="shouldHighlightIncorrect(item)"
                                :filled="item.value !== null"
                                v-html="item.value || `&nbsp;`"/>
                        <span
                                v-else-if="item.type === 'PHRASE_STRING'"
                                v-text="item.value"/>
                    </span>
                </p>
            </card>
        </div>

        <!--The options to select-->
        <div class="exercisePills__container mx-auto" v-show="false === markRequested">
            <div
                    v-for="entity in entitiesToChoose"
                    class="exerciseCard__entityPill"
                    @click="handleEntitySelection(entity.getNormalisedLocalText())"
                    v-text="entity.getLocalText().toLowerCase()"/>
        </div>
    </div>
</template>

<script>
    import {mapGetters} from 'vuex';
    import cloneDeep from 'lodash.clonedeep';
    import Exercise from '@/entities/Exercise';
    import Card from '@/components/partials/Card';
    import {shuffle} from '@/helpers/ArrayHelpers';
    import ResetBtn from '@/components/partials/Exercise/ResetBtn';
    import {normaliseLatinStrsForComparison} from '@/helpers/StringHelpers';
    import AudioPreviewBtn from '@/components/partials/Exercise/AudioPreviewBtn';
    import SpanEntityInputPill from '@/components/partials/Exercise/SpanEntityInputPill';

    export default {
        name      : 'FillInTheGapsExercise',
        data      : function () {
            return {
                phraseArray     : null,
                entitiesToChoose: [],
            };
        },
        props     : {
            exercise: {
                required : true,
                validator: ex => ex instanceof Exercise,
            },
        },
        methods   : {
            /**
             * Handle initialising the phraseArray from the formatted textString with entities
             * removed. This returns a formatted array with the string part of the phrase and
             * the interactive entities the user can select.
             *
             * @returns {void}
             */
            initPhraseArray(textString) {
                const txtStrSplit  = textString.split('{%}');
                const sentence     = [];
                let entityIterator = 0;
                txtStrSplit.forEach(str => {
                    // Push the static part of the phrase string
                    // work out position in normalised string but get it in normal string
                    // so accents and special characters are shown
                    let startPos  = this.phraseEntity.getNormalisedLocalText().indexOf(str);
                    let endPos    = startPos + str.length;
                    let strToPush = this.phraseEntity.getLocalText().substring(startPos, endPos);

                    sentence.push({
                        type : 'PHRASE_STRING',
                        value: strToPush,
                    });

                    // now push the entity object
                    if (this.correctEntitiesOrdered[entityIterator]) {
                        sentence.push({
                            type     : 'PHRASE_ENTITY',
                            value    : null,
                            isCorrect: false,
                            entity   : this.correctEntitiesOrdered[entityIterator],
                        });
                    }

                    // Inc so now round we pick up the next entity in order
                    entityIterator++;
                });

                this.phraseArray = sentence;
            },

            /**
             * Initiate the array of entities the user can choose from.
             *
             * @return {void}
             */
            initEntitiesToChoose() {
                this.entitiesToChoose = shuffle(cloneDeep(this.exercise.getAllOtherEntities()));
            },

            /**
             * Handle the user clicking one of the available entities. This simply attaches the
             * selected entity to the next available, unfilled entity in the phraseArray.
             *
             * @returns {void}
             */
            handleEntitySelection(entityText) {
                if (false === this.isCompleted) {
                    // Apply the option to the next unfilled pill
                    const item = this.phraseArray
                        .find(item => item.type === 'PHRASE_ENTITY' && item.value === null);

                    item.value     = entityText;
                    item.isCorrect = normaliseLatinStrsForComparison(entityText)
                        === item.entity.getNormalisedLocalText();

                    // Remove from available selection
                    const entityOption = this.entitiesToChoose
                        .find(item => item.getNormalisedLocalText() === entityText);
                    const index        = this.entitiesToChoose.indexOf(entityOption);
                    this.entitiesToChoose.splice(index, 1);
                }
            },

            /**
             * Determine whether the given card should show the correct validation.
             *
             * @param {*} phraseArrayItem
             *
             * @returns {Boolean}
             */
            shouldHighlightCorrect(phraseArrayItem) {
                return true === this.markRequested
                    && true === phraseArrayItem.isCorrect;
            },

            /**
             * Determine whether the given card should show the incorrect validation.
             *
             * @param {*} phraseArrayItem
             *
             * @returns {Boolean}
             */
            shouldHighlightIncorrect(phraseArrayItem) {
                return true === this.markRequested
                    && false === phraseArrayItem.isCorrect;
            },

            /**
             * Handle resetting the exercise.
             *
             * @returns {void}
             */
            resetExercise() {
                // reset the default state
                this.phraseArray      = null;
                this.entitiesToChoose = [];

                // Handle initialising the phrases and entities to choose
                if (this.phraseBaseString) {
                    this.initPhraseArray(this.phraseBaseString);
                    this.initEntitiesToChoose();
                }
            },
        },
        computed  : {
            ...mapGetters({
                markRequested    : 'Exercise/markRequested',
                exerciseHasMarked: 'Exercise/markRequested',
                interfaceIso     : 'Account/getInterfaceIso',
            }),
            audioSrc: function () {
                const entity = this.exercise.getSingleEntity();

                return entity && entity.hasAudio() ? entity.getMp3Src() : null;
            },
            phraseEntity() {
                return this.exercise.getSingleEntity();
            },
            phraseBaseString() {
                let text = this.phraseEntity.getNormalisedLocalText();

                this.otherEntityStrings.forEach(str => {
                    str      = normaliseLatinStrsForComparison(str);
                    const re = new RegExp(str, 'ig');

                    text = text.replace(re, '{%}');
                });

                return text;
            },
            correctEntitiesUnordered() {
                return this.exercise.getCorrectEntities();
            },
            correctEntitiesOrdered() {
                const entities = cloneDeep(this.correctEntitiesUnordered);
                const phrase   = this.phraseEntity.getLocalText().toLowerCase();

                // Work out where each entity appears in the phrase string
                entities
                    .forEach(entity => {
                        entity.ordering = phrase.indexOf(entity.getLocalText().toLowerCase());
                    });

                // Now actually sort the array by this ordering
                entities
                    .sort((a, b) => parseInt(a.ordering, 10) - parseInt(b.ordering, 10));

                return entities;
            },
            otherEntityStrings() {
                return this.correctEntitiesUnordered.map(entity => entity.getLocalText());
            },
            isCorrect() {
                return !!!this.phraseArray
                    .find(item => item.type === 'PHRASE_ENTITY' && item.isCorrect === false);
            },
            isCompleted() {
                return !!!this.phraseArray
                    .find(item => item.type === 'PHRASE_ENTITY' && item.value === null);
            },
            canProceed() {
                return this.isCompleted;
            },
        },
        watch     : {
            phraseBaseString: {
                handler  : function (value) {
                    if (value) {
                        this.resetExercise();
                    }
                },
                immediate: true,
            },
            isCorrect       : {
                immediate: true,
                handler  : function (value) {
                    this.$store.commit('Exercise/setIsCorrect', value);
                },
            },
            canProceed      : {
                immediate: true,
                handler  : function (value) {
                    this.$store.commit('Exercise/setCanProceed', value);
                },
            },
        },
        components: {
            Card,
            AudioPreviewBtn,
            SpanEntityInputPill,
            ResetBtn,
        },
    };
</script>
