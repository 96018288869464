<template>
    <div class="exercise max-w-sm mx-auto">
        <p class="exercise__title">
            {{ $t('EXERCISES.PHRASE_EXERCISE_TITLE') }}
        </p>
        <div class="flex items-center justify-center">
            <card
                :image-src="imageSrc"
                :audio-src="audioSrc">
                <span v-text="exercise.getSingleEntity().getLocalText()"/>
            </card>
        </div>
    </div>
</template>

<script>
    import Exercise from '@/entities/Exercise';
    import Card from '@/components/partials/Card';


    export default {
        name      : 'PhraseExercise',
        props     : {
            exercise: {
                required : true,
                validator: ex => ex instanceof Exercise,
            },
        },
        computed  : {
            imageSrc: function () {
                const entity = this.exercise.getSingleEntity();

                return entity.hasImage() ? entity.getImageSrc() : null;
            },
            audioSrc: function () {
                const entity = this.exercise.getSingleEntity();

                return entity.hasAudio() ? entity.getMp3Src() : null;
            },
        },
        components: {
            Card,
        },
    };
</script>
