<template>
    <div class="exercise mx-auto">
        <p class="exercise__title">
            {{ exercise.getSingleEntity().getTranslationFor(interfaceIso) }}
        </p>
        <div class="flex items-center justify-center">
            <card
                :image-src="imageSrc"
                :audio-src="audioSrc">
                <span v-text="exercise.getSingleEntity().getLocalText()"/>
            </card>
        </div>
    </div>
</template>

<script>
    import {mapGetters} from 'vuex';
    import Exercise from '@/entities/Exercise';
    import Card from '@/components/partials/Card';
    import AudioPreviewBtn from '@/components/partials/Exercise/AudioPreviewBtn';

    export default {
        name      : 'PhraseLocalisedExercise',
        props     : {
            exercise: {
                required : true,
                validator: ex => ex instanceof Exercise,
            },
        },
        computed  : {
            ...mapGetters({
                interfaceIso: 'Account/getInterfaceIso',
            }),
            imageSrc: function () {
                const entity = this.exercise.getSingleEntity();

                return entity && entity.hasImage() ? entity.getImageSrc() : null;
            },
            audioSrc: function () {
                const entity = this.exercise.getSingleEntity();

                return entity && entity.hasAudio() ? entity.getMp3Src() : null;
            },
        },
        components: {
            AudioPreviewBtn,
            Card,
        },
    };
</script>
