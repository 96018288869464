<template>
    <button
        class="exerciseCard__actionBtn"
        @click.prevent="playAudio">
        <svg class="h-3/5 w-3/5 fill-current" viewBox="0 0 20 20">
            <path
                d="M5 7H1v6h4l5 5V2L5 7zm11.364 9.364A8.972 8.972 0 0019 10a8.972 8.972 0 00-2.636-6.364L14.95 5.05A6.978 6.978 0 0117 10a6.978 6.978 0 01-2.05 4.95l1.414 1.414zm-2.828-2.828A4.984 4.984 0 0015 10c0-1.38-.56-2.63-1.464-3.536L12.12 7.88A2.99 2.99 0 0113 10a2.99 2.99 0 01-.879 2.121l1.415 1.415z"
                fill-rule="evenodd"/>
        </svg>
    </button>
</template>

<script>
    export default {
        name   : 'AudioPreviewBtn',
        data   : function () {
            return {
                audio: null,
            };
        },
        props  : {
            src: {
                required: true,
                type    : String,
            },
        },
        methods: {
            playAudio() {
                this.audio ? this.audio.play() : null;
            },
        },
        watch  : {
            src: {
                immediate: true,
                handler  : function (value) {
                    this.audio = new Audio(value);
                },
            },
        },
    };
</script>
