<template>
    <div class="unitProgressNav__container">
        <div class="w-1/12">
            <exit-button
                class="unitProgressNav__exitBtn"
                @click="$emit('exit')"/>
        </div>
        <div class="w-10/12 px-2">
            <progress-bar :progress="progress"/>
        </div>
        <div class="w-1/12">
            &nbsp;
        </div>
    </div>
</template>

<script>
    import ExitButton from '@/components/ExitButton';
    import ProgressBar from '@/components/ProgressBar';

    export default {
        name      : 'ProgressNavigation',
        props     : {
            progress: {
                required: true,
                type    : Number,
            },
        },
        components: {
            ExitButton,
            ProgressBar,
        },
    };
</script>
