<template>
    <div v-if="exercise">
        <phrase-exercise
            :exercise="exercise"
            v-if="exType === types.PHRASE"/>
        <phrase-localised-exercise
            :exercise="exercise"
            v-if="exType === types.PHRASE_LOCALISED"/>
        <multiple-choice-exercise
            :exercise="exercise"
            v-else-if="exType === types.MULTIPLE"/>
        <multiple-choice-exercise
            :exercise="exercise"
            v-else-if="exType === types.MULTIPLE_NO_IMG"
            :show-images="false"/>
        <listen-and-type-exercise
            :exercise="exercise"
            v-else-if="exType === types.TYPE"/>
        <fill-in-the-gaps-exercise
            :exercise="exercise"
            v-else-if="exType === types.FILL_IN_GAPS"/>
    </div>
</template>

<script>
    import {mapGetters} from 'vuex';
    import PhraseExercise from '@/components/partials/Exercise/PhraseExercise';
    import FillInTheGapsExercise from '@/components/partials/Exercise/FillInTheGapsExercise';
    import ListenAndTypeExercise from '@/components/partials/Exercise/ListenAndTypeExercise';
    import MultipleChoiceExercise from '@/components/partials/Exercise/MultipleChoiceExercise';
    import PhraseLocalisedExercise from '@/components/partials/Exercise/PhraseLocalisedExercise';
    import {
        UNIT_TYPE_MULTIPLE,
        UNIT_TYPE_MULTIPLE_NO_IMAGES,
        UNIT_TYPE_PHRASE,
        UNIT_TYPE_LISTEN_TYPE,
        UNIT_TYPE_FILL_IN_THE_GAPS,
        UNIT_TYPE_PHRASE_LOCALISED,
    } from '@/constants/Unit';

    export default {
        name      : 'Exercise',
        data() {
            return {
                types: {
                    PHRASE          : UNIT_TYPE_PHRASE,
                    PHRASE_LOCALISED: UNIT_TYPE_PHRASE_LOCALISED,
                    MULTIPLE        : UNIT_TYPE_MULTIPLE,
                    MULTIPLE_NO_IMG : UNIT_TYPE_MULTIPLE_NO_IMAGES,
                    TYPE            : UNIT_TYPE_LISTEN_TYPE,
                    FILL_IN_GAPS    : UNIT_TYPE_FILL_IN_THE_GAPS,
                },
            };
        },
        computed  : {
            ...mapGetters({
                exercise: 'Exercise/getExerciseEntity',
            }),
            exType() {
                return this.exercise.getType();
            },
        },
        components: {
            PhraseExercise,
            PhraseLocalisedExercise,
            MultipleChoiceExercise,
            ListenAndTypeExercise,
            FillInTheGapsExercise,
        },
    };
</script>
