<template>
    <span class="exerciseCard__entityInputField" :class="classes">
        <slot/>
    </span>
</template>

<script>
    export default {
        name    : 'SpanEntityInputPill',
        props   : {
            showIncorrectValidation: {
                required: false,
                default : false,
                type    : Boolean,
            },
            showCorrectValidation  : {
                required: false,
                default : false,
                type    : Boolean,
            },
            filled                 : {
                type   : Boolean,
                default: false,
            },
        },
        computed: {
            classes: function () {
                let classes = this.filled ? 'exerciseCard__entityInputField--filled' : '';

                if (this.filled === true) {
                    classes += this.showCorrectValidation ? ' exerciseCard__entityInputField--correct' : '';
                    classes += this.showIncorrectValidation ? ' exerciseCard__entityInputField--incorrect' : '';
                }

                return classes;
            },
        },
    };
</script>
