<template>
    <div
        class="exerciseCard exerciseCard__container"
        :class="classes"
        @click="$emit('click')">
        <img
            v-if="showImage && imageSrc"
            class="exerciseCard__image"
            :src="imageSrc"/>
        <div class="h-8 -mb-2 relative" v-if="audioSrc">
            <slot name="actions">
                <audio-preview-btn
                        class="mx-auto"
                        :src="audioSrc"/>
            </slot>
        </div>
        <div class="exerciseCard__text">
            <slot/>
        </div>
    </div>
</template>

<script>
    import AudioPreviewBtn from '@/components/partials/Exercise/AudioPreviewBtn';

    export default {
        name      : 'Card',
        props     : {
            active                 : {
                type   : [Boolean, null],
                default: null,
            },
            imageSrc               : {
                type   : String,
                default: null,
            },
            audioSrc: {
                type: String,
                default: null,
            },
            showImage              : {
                type   : Boolean,
                default: true,
            },
            showIncorrectValidation: {
                required: false,
                default : false,
            },
            showCorrectValidation  : {
                required: false,
                default : false,
            },
            isClickable            : {
                required: false,
                default : false,
            },
        },
        computed  : {
            classes() {
                let cls = '';
                cls += this.active === true ? ' exerciseCard--active' : '';
                cls += this.active === false ? ' exerciseCard--inactive' : '';
                cls += this.isClickable ? ' cursor-pointer ' : '';

                if (this.showIncorrectValidation === true) {
                    cls += ' exerciseCard--incorrect ';
                } else if (this.showCorrectValidation === true) {
                    cls += ' exerciseCard--correct ';
                }

                return cls;
            },
        },
        components: {
            AudioPreviewBtn,
        },
    };
</script>
