<template>
    <div class="exercise max-w-sm mx-auto">
        <div class="flex items-center justify-center">
            <card
                class="exerciseCard--listenAndType"
                :image-src="imageSrc"
                :audio-src="audioSrc">
                <div class="w-full">
                    <p class="exerciseCard__instructions"
                       v-text="$t('EXERCISES.LISTEN_AND_TYPE_TITLE')"/>
                    <input
                        :class="inputClass"
                        class="exerciseCard__input"
                        type="text"
                        :disabled="markRequested"
                        v-model="value">
                </div>
            </card>
        </div>
    </div>
</template>

<script>
    import {mapGetters} from 'vuex';
    import Exercise from '@/entities/Exercise';
    import Card from '@/components/partials/Card';
    import {normaliseLatinStrsForComparison, removeNonAlphaCharacters} from '@/helpers/StringHelpers';
    import AudioPreviewBtn from '@/components/partials/Exercise/AudioPreviewBtn';

    export default {
        name      : 'ListenAndTypeExercise',
        data      : function () {
            return {
                value: null,
            };
        },
        props     : {
            exercise: {
                required : true,
                validator: ex => ex instanceof Exercise,
            },
        },
        computed  : {
            ...mapGetters({
                markRequested: 'Exercise/markRequested',
            }),
            imageSrc: function () {
                const entity = this.exercise.getSingleEntity();

                return entity && entity.hasImage() ? entity.getImageSrc() : null;
            },
            audioSrc: function () {
                const entity = this.exercise.getSingleEntity();

                return entity && entity.hasAudio() ? entity.getMp3Src() : null;
            },
            isCorrect() {
                const normalisedUserEntry     = normaliseLatinStrsForComparison(this.value);
                const normalisedCorrectAnswer = this.exercise.getSingleEntity()
                    .getNormalisedLocalText();

                // Normalise and lowercase both entries to check
                const firstCheck = normalisedUserEntry === normalisedCorrectAnswer;

                // If first fails try again but this time removing punctuation e.g. !
                const secondCheck = removeNonAlphaCharacters(normalisedUserEntry)
                    === removeNonAlphaCharacters(normalisedCorrectAnswer);

                return firstCheck || secondCheck;
            },
            canProceed() {
                return this.value !== null && this.value.length > 0;
            },
            inputClass() {
                if (this.markRequested) {
                    return this.isCorrect
                        ? `border-green-500`
                        : `border-red-500`;
                }
            },
        },
        watch     : {
            isCorrect : {
                immediate: true,
                handler  : function (value) {
                    this.$store.commit('Exercise/setIsCorrect', value);
                },
            },
            canProceed: {
                immediate: true,
                handler  : function (value) {
                    this.$store.commit('Exercise/setCanProceed', value);
                },
            },
        },
        components: {
            AudioPreviewBtn,
            Card,
        },
    };
</script>
