<template>
    <div class="progressBar">
        <div class="progressBar__bar" :style="progressStyle">
            <p v-show="showProgressPercentage">{{ Math.round(progress) }}%</p>
        </div>
    </div>
</template>

<script>
    export default {
        name    : 'ProgressBar',
        props   : {
            progress      : {
                default: 0,
                type   : Number,
            },
            showPercentage: {
                type   : Boolean,
                default: false,
            },
        },
        computed: {
            progressStyle() {
                return `width: ${Math.max(this.progress, 1)}%`;
            },
            showProgressPercentage() {
                return (true === this.showPercentage) && (this.progress > 19);
            },
        },
    };
</script>
