<template>
    <div class="unitPage">
        <div class="mx-auto container pt-12 pb-16">
            <loading-capsule :loaded="requiredContentHasLoaded">
                <div class="max-w-base mx-auto"
                     v-if="requiredContentHasLoaded">
                    <div class="text-center">
                        <h1 class="hidden sm:block" v-html="summaryTitleText"></h1>
                        <h2 class="block sm:hidden" v-html="summaryTitleText"></h2>
                    </div>

                    <!--Daily streak-->
                    <div class="pt-4 text-center">
                        <h3 class="pb-2">{{ $t('PROGRESS.DAILY_STREAK') }}</h3>
                        <daily-streak
                            :streak="streakLength"
                            :has-completed-today="dailyStreakIncludesToday"/>
                    </div>

                    <!--Actions-->
                    <div class="w-full px-4 mb-2 fixed bottom-0 left-0">
                        <div class="max-w-base mx-auto">
                            <div class="w-full mb-2">
                                <button
                                    v-if="null === getNextUnitUuid || false === userHasPassed"
                                    class="button__btn my-1 button__btn--primary button__btn--fullWidth"
                                    @click="$router.push({name: 'app.dashboard'})">
                                    {{ $t('CORE.CONTINUE') }}
                                </button>
                                <button
                                    v-else
                                    class="button__btn my-1 button__btn--primary button__btn--fullWidth"
                                    @click="nextUnit">
                                    {{ $t('CORE.NEXT_LESSON_CTA') }}
                                </button>
                            </div>
                            <div class="w-full mt-2">
                                <router-link
                                    class="button__btn my-1 button__btn--secondary button__btn--fullWidth"
                                    :to="{ name: 'app.progress' }">
                                    View progress
                                </router-link>
                            </div>
                        </div>
                    </div>
                </div>
            </loading-capsule>
        </div>
    </div>
</template>

<script>
    import {mapGetters} from 'vuex';
    import Btn from '@/components/Btn';
    // import {AUDIO_BASE_64_CONGRATULATIONS} from '@/constants/Audio';
    import SuccessImage from '@/components/partials/Unit/SuccessImage';
    import LoadingCapsule from '@/components/structure/LoadingCapsule';
    import DailyStreak from '@/components/partials/UserProgress/DailyStreak';

    export default {
        name      : 'UnitSuccess',
        computed  : {
            ...mapGetters({
                userHasPassed           : 'Unit/hasUserPassed',
                courseFetched           : 'Course/courseHasFetched',
                activeUnitUuid          : 'Unit/getActiveUnitUuid',
                activeLesson            : 'Course/getActiveCourseLesson',
                streakLength            : 'DailyProgress/getStreakLength',
                userProgressFetched     : 'Progress/userProgressHasFetched',
                dailyProgressFetched    : 'DailyProgress/dailyProgressHasFetched',
                dailyStreakIncludesToday: 'DailyProgress/getHasDailyProgressToday',
            }),
            getNextUnitUuid : function () {
                // Find the unit from the active lesson mapped object in course store
                const unit = this.activeLesson
                    .units
                    .find(unit => unit.id === this.activeUnitUuid);

                return unit ? unit.next_unit_uuid : null;
            },
            requiredContentHasLoaded() {
                return this.userProgressFetched
                    && this.dailyProgressFetched
                    && this.courseFetched;
            },
            summaryTitleText: function () {
                const text = this.userHasPassed
                    ? this.$t('EXERCISES.RESULTS.PASS')
                    : this.$t('EXERCISES.RESULTS.FAIL');

                return text.replace('-', '<br/>');
            },
        },
        methods   : {
            nextUnit: function () {
                this.$store.dispatch('Unit/updateActiveUnitUuid', this.getNextUnitUuid)
                    .then(() => {
                        this.$router.push({name: 'app.learn.unit'});
                        this.$store.dispatch('Unit/fetchUnit');
                    });
            },
        },
        watch     : {
            activeUnitUuid          : {
                immediate: true,
                handler  : function (value) {
                    if (null === value) {
                        this.$router.push({name: 'app.dashboard'});
                    }
                },
            },
            requiredContentHasLoaded: {
                immediate: true,
                handler  : function (bool) {
                    if (bool && this.userHasPassed) {
                        // (new Audio(AUDIO_BASE_64_CONGRATULATIONS)).play();
                        // No success music for now :(
                    }
                },
            },
        },
        components: {
            SuccessImage,
            Btn,
            LoadingCapsule,
            DailyStreak,
        },
    };
</script>
