<template>
    <div>
        <!--Check button-->
        <btn
            class="mx-auto button__btn--primary button__btn--lockedWidth"
            @click="handleProceedingToNextExercise"
            v-text="$t('CORE.CONTINUE')"/>
    </div>
</template>

<script>
    import {mapGetters} from 'vuex';
    import Btn from '@/components/Btn';
    import {TYPE_EXERCISE, TYPE_ENTITY, EXERCISE_TYPES_TO_NOT_LOG} from '@/constants/Progress';

    export default {
        name      : 'ProceedControls',
        methods   : {
            handleProceedingToNextExercise() {
                this.addProgressToProgressStore(this.exercise, this.correct);
                this.$store.dispatch('Unit/markCurrentExercise', this.correct);
            },
            addProgressToProgressStore(exercise, isCorrect) {
                let payloads = [];

                // Record the exercise progress if it isn't blacklisted
                if (!EXERCISE_TYPES_TO_NOT_LOG.includes(exercise.getType())) {
                    payloads.push({
                        type  : TYPE_EXERCISE,
                        id    : exercise.getId(),
                        passed: isCorrect,
                    });
                }

                // If there's a single entity record this too
                if (this.exercise.getSingleEntity()) {
                    payloads.push({
                        type  : TYPE_ENTITY,
                        id    : exercise.getSingleEntity().getId(),
                        passed: isCorrect || true,
                    });
                }

                // Push to payload batch
                payloads.forEach(payload => {
                    this.$store.dispatch('ProgressDispatcher/addProgressRecord', payload)
                });
            },
        },
        computed  : {
            ...mapGetters({
                correct : 'Exercise/isCorrect',
                exercise: 'Exercise/getExerciseEntity',
            }),
        },
        components: {
            Btn,
        },
    };
</script>
