<template>
    <div class="dailyStreak">
        <div class="dailyStreak__container">
            <div
                v-for="(day, index) in days"
                :class="getClassForDay(index + 1)"
                class="dailyStreak__circle"/>
        </div>
    </div>
</template>

<script>
    export default {
        name    : 'DailyStreak',
        data    : function () {
            return {
                days: new Array(7),
            };
        },
        props   : {
            streak           : {
                type    : Number,
                required: true,
            },
            hasCompletedToday: {
                type    : Boolean,
                required: true,
            },
        },
        computed: {
            getStreakDays: function () {
                return this.hasCompletedToday
                    ? Math.min(this.streak, 7)
                    : Math.min(this.streak, 6);
            },
        },
        methods : {
            getClassForDay: function (day) {
                const streak = this.getStreakDays;

                if (day <= streak) return 'dailyStreak__circle--green';

                if (false === this.hasCompletedToday && (day === streak + 1)) {
                    return 'dailyStreak__circle--blue dailyStreak__circle--faded';
                }

                return 'dailyStreak__circle--blue';
            },
        },
    };
</script>
